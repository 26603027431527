import React, { useEffect, useState } from "react";
import WhatsappIcon from "../assets/img/whatsapp.png";
export const Whatsapp = () => {

  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const getGreeting = () => {
    const hour = time.getHours();

    if (hour < 12) {
      return 'Good morning!';
    } else if (hour < 18) {
      return 'Good afternoon!';
    } else {
      return 'Good evening!';
    }
  };


  const sendMessage = () => {
    const phoneNumber = '+919970732705';
    const message = `Greetings,
    Hello ${getGreeting()},
    I want to know more about Ekatta Studio.
    Write your query here…`;
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    window.open(url, '_blank');
  };
  return (
    <>
      <div className="sticky_whatsapp_wrapper"  onClick={sendMessage}>
        <img className="whatsapp_icon" src={WhatsappIcon} />
        <span className="hotspot"></span>
      </div>
    </>
  );
};
