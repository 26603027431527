import React from "react";
import { Link } from "react-router-dom";

const TopNav = () => {
  return (
    <>
      <div className="common_bg d-none d-lg-block">
        <div className="align-items-center container d-flex justify-content-between">
          <div className="common_info">
            <label>
              <i className="fa fa-phone text-white px-2"></i>{" "}
              <Link
                to=""
                onClick={() => (window.location = "tel:9970732705")}
                className="text-decoration-none text-dark"
              >
                +91 9970732705 ,
              </Link>
              <Link
                to=""
                onClick={() => (window.location = "tel:9518944272")}
                className="text-decoration-none text-dark"
              >
                {" "}
                +91 9518944272
              </Link>
            </label>
            <label>
              <i className="fa fa-envelope ms-3 text-white"></i>
              <Link
                to=""
                onClick={() =>
                  (window.location = "mailto:ekattastudio@gmail.com")
                }
                className="text-dark text-decoration-none ms-2 nav-email"
              >
                ekattastudio@gmail.com{" "}
              </Link>
            </label>
          </div>
          <div className="">
            <div className="h-0 w-0">
              <ul className="d-flex justify-content-center gap-1 list-unstyled m-2 social-icon-two">
                <li>
                  <Link to={"https://wa.me/919970732705"} target="_blank">
                    <i className="px-2 text-white fa fa-whatsapp"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to={"https://www.facebook.com/shyam.untwal.7/"}
                    target="_blank"
                  >
                    <i className="px-2 text-white fa fa-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to={
                      "https://www.instagram.com/ekatta.studio?igsh=MThlM2x2YnJpbTlnYQ=="
                    }
                    target="_blank"
                  >
                    <i className="px-2 text-white fa fa-instagram"></i>
                  </Link>
                </li>

                <li>
                  <Link to={"https://pin.it/5sdD4hrLg"} target="_blank">
                    <i className="px-2 text-white fa fa-pinterest"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopNav;
