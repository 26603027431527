import { Spinner } from "react-bootstrap";
import bgLoader from "./../assets/img/icon/preloader.svg";

const Loader = ({ loading }) => {
  return (
    <div
      style={{
        // height: "100vh",
        position:'fixed',
        top:'50%',
        left:'45%',
        width: "100vw",
        display: loading ? "block" : "none",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 99,
      }}
    >
      <div className="backdrop" style={{
        position:'fixed',
        top:0,
        left:0,
        width:'100vw',
        height:'100vh',
        backgroundColor:'black',
        opacity:'0.5',
        zIndex:999,

      }}></div>
      <img
        src={bgLoader}
        alt="loader background img"
        style={{
          position: "fixed",
          height: "21vh",
          zIndex:'9999'
        }}
      />
      
        
    </div>
  );
};

export default Loader;
