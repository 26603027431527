import React, { useState, useEffect, useRef } from "react";
import { Button, Nav, NavLink, Navbar, Form } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../assets/img/icon/logo.png";

const Header = ({ selectNav, setSelectedNav }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(null);

  const scrolledStyle = {
    color: "black",
    fontWeight: "500",
    textDecoration: "none",
  };

  const unScrolledStyle = {
    textDecoration: "none",
    color: "white",
    fontWeight: "500",
  };

  const navigationRef = useRef();

  const navigationItems = [
    { name: "Home", href: "#home", isLink: false },
    { name: "About", href: "#about", isLink: false },
    { name: "Services", href: "#services", isLink: false },
    { name: "Projects", href: "/projects", isLink: true, twoPage:true },
    { name: "VR Tour", href: "/vr", isLink: true, twoPage:true },
    { name: "Contact", href: "#contact", isLink: false },
  ];

  const handleNavigation = (item) => {
    setActiveItem(item.name);
    document.title = item.name;

    if (item.isLink) {
      navigate(item.href);
    } else {
      if (location.pathname !== "/") {
        navigate("/");
        setSelectedNav(item.href.substring(1));
      } else {
        navigateToHome(item.href.substring(1));
      }
    }

    const navbarCollapse = document.querySelector(".navbar-collapse");
    if (navbarCollapse.classList.contains("show")) {
      navbarCollapse.classList.remove("show");
    }

    const navbarToggler = document.querySelector(".navbar-toggler");
    if (navbarToggler && !navbarToggler.classList.contains("collapsed")) {
      navbarToggler.click();
    }
  };

  const navigateToHome = (id) => {
    const homeElement = document.getElementById(id);
    if (homeElement) {
      homeElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.pageYOffset > 10);
    };

    const handleNavigationScroll = () => {
      if (location.hash) {
        const targetElement = document.getElementById(location.hash.substring(1));
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        window.scrollTo(0, 0);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleNavigationScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location]);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      
      entries.forEach((entry) => {
     
        if (entry.isIntersecting) {
          const targetId = entry.target.getAttribute("id");
          const targetItem = navigationItems.find((item) => item.href.substring(1) === targetId);
          setActiveItem(targetItem ? targetItem.name : null);
        }
      });
    }, observerOptions);

    const sections = navigationItems
      .filter((item) => !item.isLink || item.twoPage)
      .map((item) => document.getElementById(item.href.substring(1)));

    sections.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      sections.forEach((section) => {
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, [navigationItems]);

  const NavigationLink = ({ item }) => (
    <div className="nav_item w-100" key={item.name}>
      {!item.isLink ? (
        <NavLink
          key={item.name}
          className={activeItem === item.name ? "active" : ""}
          onClick={() => handleNavigation(item)}
          style={isScrolled ? scrolledStyle : unScrolledStyle}
        >
          {isScrolled ? item.name : item.name.toUpperCase()}
        </NavLink>
      ) : (
        <Link style={{ textDecoration: "none" }} to={item.href}>
          <NavLink
            onClick={() => handleNavigation(item)}
            className={activeItem === item.name ? "active" : ""}
            style={isScrolled ? scrolledStyle : unScrolledStyle}
          >
            {isScrolled ? item.name : item.name.toUpperCase()}
          </NavLink>
        </Link>
      )}
    </div>
  );

  return (
    <>
      <Navbar
        collapseOnSelect
        bg={isScrolled ? "light" : "black"}
        variant="light"
        expand="lg"
        sticky="top"
        className={`${
          isScrolled
            ? "sticky w-100 d-block animated slideDown"
            : "position-absolute ms-auto me-auto start-0 end-0 radius px-lg-4 px-0 py-4"
        } `}
      >
        <div className="container">
          <Navbar.Brand>
            <Link style={{ textDecoration: "none" }} to={"/"}>
              <img src={Logo} alt="logo" />
            </Link>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav animated" />
          <Navbar.Collapse id="basic-navbar-nav ms-2" ref={navigationRef}>
            <Nav className="ms-auto mobile-navbar gap-2 fw-bold">
              {navigationItems.map((item) => (
                <NavigationLink key={item.name} item={item} />
              ))}
            </Nav>
            <Form className="ms-lg-3 pt-3 pt-lg-0">
              <Button
                onClick={() => {
                  handleNavigation({ name: "contact", href: "#contact" });
                }}
                className="theme-btn common_button"
              >
                Get Quote
              </Button>
            </Form>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </>
  );
};

export default Header;
