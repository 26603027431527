import React, { useEffect, useState, Suspense } from "react";
import { Router, Routes, Route, useLocation } from "react-router-dom";
import Header from "./common/navbar";
import TopNav from "./common/topNav";
import Footer from "./common/footer";
import { routes } from "./utils/routes";
import Scroller from "./common/Scroller";
import Home from "./features/home/home";
import { Whatsapp } from "./common/whatsapp";
import Loader from "./common/loader";
import { useSelector } from "react-redux";

function App() {
  const [selectNav, setSelectedNav] = useState(null);

  const [executionTime, setExecutionTime] = useState(0);
  const Home = React.lazy(() => import("./features/home/home"));
  useEffect(() => {
    const startTime = performance.now();
    const endTime = performance.now();
    const totalExecutionTime = endTime - startTime;
    setExecutionTime(totalExecutionTime);
  }, []);

  const loader = useSelector((state) => state.counter.loader);

  const setNull = () => {};

  return (
    <div>
      {<Loader loading={loader} />}
      <Scroller />
      <TopNav selectNav={selectNav} setSelectedNav={setSelectedNav} />
      <div style={{ position: "relative" }}>
        <Header selectNav={selectNav} setSelectedNav={setSelectedNav} />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes scrollRestoration="auto">
            {routes.map((route, index) => {
              if (route.path === "/") {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <Home
                        selectNav={selectNav}
                        setSelectedNav={setSelectedNav}
                      />
                    }
                  />
                );
              } else {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.component}
                  />
                );
              }
            })}
          </Routes>
        </Suspense>
        <Whatsapp />
        <Footer />
      </div>
    </div>
  );
}

export default App;
