import React, { useEffect, useState, Suspense } from "react";
import OwlCarousel from "react-owl-carousel";
import About from "../about/about";
import { fetchHome } from "../../axios/axios";
import { DOMAIN } from "../../api/url";
import Social from "../social-media/Social";
import homeStyles  from '../home/home.module.css';
const Specialization = React.lazy(() => import("../specialization/specialization"));
const Counter = React.lazy(() => import("../counter/counter"));
const Project = React.lazy(() => import("../projects/project"));
const Director = React.lazy(() => import("../director/director"));
const Virtual = React.lazy(() => import("../vr_reality/virtual"));
const Testimonial = React.lazy(() => import("../testimonial/testimonial"));

export default function Home({ selectNav, setSelectedNav }) {
  const [slide, setSlide] = useState([]);
  const [scrollCount, setScrollCount] = useState(0);

  const fetchData = async () => {
    await fetchHome().then((res) => {
      setSlide(res.data.home);
    });
  };

  const navigateToHome = (id) => {
    const homeElement = document.getElementById(id);
    if (homeElement) {
      homeElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    fetchData();
    setTimeout(() => {
      if (selectNav) {
        navigateToHome(selectNav);
        setSelectedNav(null);
      }
    }, 2000);


  }, []);

  return (
    <React.Fragment>
      {slide.length > 0 && (
        <OwlCarousel
          id="home"
          items={1}
          className="hero_slider owl-theme"
          loop
          nav={false}
          margin={8}
          autoplay={true}
          autoplayTimeout={4000}
          smartSpeed={2000}
          animateOut={"fadeOut"}
          mouseDrag={false}
        >
          {slide.map((item, index) => {
            return (
              <div
                key={"sliderdiv" + index}
                className={"bgimg"}
                style={{
                  backgroundImage: `url(${DOMAIN + item.photoPath})`,
                  backgroundRepeat: " no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <div className="d-flex flex-column Slider_Heading align-items-center justify-content-center h-100">
                  <label className={homeStyles.mainLabel}>{item.subTitle}</label>
                  <h1 className={homeStyles.mainTitle}>{item.title}</h1>
                </div>
              </div>
            );
          })}
        </OwlCarousel>
      )}
      <About />

      <Suspense fallback={<div>Loading...</div>}>
         <Specialization />
         <Counter />
         <Project />
         <Virtual />
         <Testimonial />
         <Social/>
       <Director/>

        
        
      </Suspense>

   
    </React.Fragment>
  );
}
