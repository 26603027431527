import AllProject from "../features/all-projects/allprojects";
import Home from "../features/home/home";
import VRNew from "../features/vr-tour/VRNew";
import VR from "../features/vr-tour/vr";
import { routePath } from "./routePath";

export const routes = [
  {
    path: routePath.ROOT,
    component: <Home />,
  },
  {
    path: routePath.VR,
    component: <VRNew />,
  },
  {
    path: routePath.PROJECTS,
    component: <AllProject />,
  },
];
