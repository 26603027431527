import React, { useState, useEffect } from "react";

function Scroller() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 100) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className="scroll-to-top scroll-to-target"
      style={{ display: showButton ? "block" : "none" }}
    >
      <div className="top_to_scroll_link" onClick={scrollToTop}>
        <span className="fa fa-arrow-circle-o-up"></span>
      </div>
    </div>
  );
}

export default Scroller;
