import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { Pannellum } from "pannellum-react";
import VRHALL from "../../assets/img/vr/hall_360_render.png";
import VRDINNING from "../../assets/img/vr/dinning_360_render.png";
import VRKITCHEN from "../../assets/img/vr/kitchen_360_render.png";

import PREVEWIWHALL from "../../assets/img/vr/hall-thumb-img-1.png";
import PREVEWDNNING from "../../assets/img/vr/dinning-thumb-img-2.png";
import PREVEWKITCHEN from "../../assets/img/vr/kitchen-thumb-img-3.png";

import THUMBHALL from "../../assets/img/vr/thumd-img-2.png";
import THUMBDNNING from "../../assets/img/vr/thumd-img-3.png";
import THUMBKITCHEN from "../../assets/img/vr/thumd-img-1.png";
import { fetchVrTour } from "../../axios/axios";
import { DOMAIN } from "../../api/url";
import Loader from "../../common/loader";

const VRNew = () => {
  const [projectArr, setProjectArr] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [currentProject, setCurrentProject] = React.useState();

  const [currentScene, setCurrentScene] = React.useState({});

  const setProject = async (project) => {
    await setCurrentProject(project);
    setCurrentScene(project.vr_tour_scene[0]);
  };

  const fetchData = async () => {
    setLoading(true);
    await fetchVrTour().then((res) => {
      const data = res.data;
      setProjectArr(data.VrTour);
      // setCurrentProject(data.VrTour[0]);
      setProject(data.VrTour[0]);
      setLoading(false);
    });
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const setScene = (sceneId) => {
    setCurrentScene(currentProject.vr_tour_scene[sceneId]);
  };

  const handleHotspotClick = async (selectedImageId) => {
    const clickedImage = await currentProject.vr_tour_scene.find(
      (image) => image.imageId === parseInt(selectedImageId)
    );

    setCurrentScene(clickedImage);
  };

  const generateProjectThumbnails = () => {
    {
      console.log(currentProject);
    }

    return projectArr.map((project) => (
      <div key={project} className={`col-2 mb-2 main_tab_col`}>
        <Nav.Item>
          <Nav.Link className={`bg-white`} onClick={() => setProject(project)}>
            <img
              style={{ height: "130px", width: "100%", objectFit: "cover" }}
              className="img-fluid"
              src={DOMAIN + project.preview}
              alt=""
            />{" "}
            <p
              className={`mt-2 text-dark fs-bold  ${
                currentProject.title === project.title
                  ? "border-bottom border-2 border-dark"
                  : ""
              } `}
            >
              {project?.title}
            </p>
          </Nav.Link>
        </Nav.Item>
      </div>
    ));
  };

  const generateSceneThumbnails = () => {
    if (currentProject) {
      return currentProject?.vr_tour_scene?.map((sceneId, key) => (
        <Nav.Item key={sceneId}>
          <Nav.Link className="bg-white" onClick={() => setScene(key)}>
            <img
              style={{ height: "150px", width: "150px", objectFit: "cover" }}
              className="img-fluid"
              // src={sceneId.image}
              src={DOMAIN + sceneId.image}
              alt="thumbnails"
            />
            <p></p>
          </Nav.Link>
        </Nav.Item>
      ));
    }
    return null;
  };

  const generateHotsports = (hotSpots) => {
    try {
      const newArray = JSON.parse(hotSpots);

      return newArray?.map((hotspot, key) => (
        <Pannellum.Hotspot
          handleClick={() => handleHotspotClick(hotspot.imageId)}
          key={hotspot.type + key}
          type={hotspot.type}
          pitch={hotspot.pitch}
          yaw={hotspot.yaw}
          previewTitle={hotspot.imageId}
          text={hotspot?.title}
        />
      ));
    } catch (err) {}
  };

  return (
    <div>
      <section className="bg-white mb-5">
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <Container
            style={{ transform: "translateY(100px)", paddingBottom: "100px" }}
          >
            <div className="sec_title">
              <span className="float_text text-gray">VR</span>
              <h2 className="pt-3 text-black">Virtual Reality Tour</h2>
            </div>
            <div className="row main_tabs">{generateProjectThumbnails()}</div>
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={currentScene?.title}
            >
              <Row className="m-0">
                <Col lg={2} md={12}>
                  <Nav
                    variant="pills"
                    className="d-flex flex-column flex-md-row align-items-center flex-wrap nav nav-pills  thumbnail-nav"
                  >
                    {currentProject && generateSceneThumbnails()}
                  </Nav>
                </Col>
                <Col lg={10} md={12}>
                  <Tab.Content className="mt-4 mt-md-0">
                    <div
                      key={currentScene?.title}
                      eventKey={currentScene?.title}
                    >
                      
                      <Pannellum
                        width={"100%"}
                        height={"500px"}
                        title={currentScene?.title}
                        image={DOMAIN + currentScene.image}
                        pitch={currentScene.pitch}
                        yaw={currentScene.yaw}
                        hfov={130}
                        autoLoad
                        showControls={true}
                        showFullscreenCtrl={true}
                        showZoomCtrl={true}
                        orientationOnByDefault={true}
                        crossOrigin={"anonymous"}
                        >
                        {generateHotsports(currentScene.hotspots)}
                        </Pannellum>
                    </div>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        )}
      </section>
    </div>
  );
};

export default VRNew;
