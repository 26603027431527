import React, { useEffect } from "react";
import facebook from "../../assets/img/facebook.png";
import instagram from "../../assets/img/instagram.png";
import pinterest from "../../assets/img/pinterest.png";
const Social = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.type = "text/javascript";
    script.dataset.pinBuild = "doBuild";
    script.src = "//assets.pinterest.com/js/pinit.js";
    document.body.appendChild(script);
    if (window.doBuild) window.doBuild();
  }, []);
  return (
    <div>
      <section className="pt-5">
        <div className="container">
          <div className="sec-title">
            <span className="float-text">Media</span>
            <h2>Find us on social media.</h2>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 mt-lg-0 mt-3">
              <div className="d-flex align-items-center mb-3">
                <img
                  width={30}
                  height={30}
                  src={facebook}
                  alt="facebook logo"
                />
                <h4 className="ms-2 mb-0">Facebook</h4>
              </div>
              <div
                className="w-100 "
                style={{ height: "468px", overflow: "auto" }}
              >
                <iframe
                  src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D61557588470583&tabs=timeline&width=400&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                  width="100%"
                  height="500"
                  style={{ border: "none", overflow: "hidden" }}
                  scrolling="no"
                  frameborder="0"
                  allowfullscreen="true"
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                ></iframe>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-lg-0 mt-3">
              <div className="d-flex align-items-center mb-3">
                <img
                  width={30}
                  height={30}
                  src={instagram}
                  alt="instagram logo"
                />
                <h4 className="ms-2 mb-0">Instagram</h4>
              </div>
              <div
                className="w-100 rounded-4"
                style={{ height: "468px", overflow: "auto" }}
              >
                <iframe
                    width="100%"
                    height="500"
                  // src="https://www.instagram.com/ekatta.studio/embed"
                  src="https://www.instagram.com/p/Cxzt_iZITSG/embed"
                  frameborder="0"
                ></iframe>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-lg-0 mt-3">
              <div className="d-flex align-items-center mb-3">
                <img
                  width={30}
                  height={30}
                  src={pinterest}
                  alt="pinterest logo"
                />
                <h4 className="ms-2 mb-0">Pinterest</h4>
              </div>
              <div
                className="w-100 rounded-4"
                style={{ height: "468px", overflow: "auto" }}
              >
                <a
                  data-pin-do="embedBoard"
                  data-pin-board-width="400"
                  data-pin-scale-height="380"
                  data-pin-scale-width="80"
                  href="https://www.pinterest.com/shyamuntwal/bed-room/"
                ></a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Social;
