// store.js
import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./counterReducer";

export const store = configureStore({
  reducer: {
    counter: counterReducer // Add your reducers here
  },
  devTools: process.env.NODE_ENV !== 'production', 
});
