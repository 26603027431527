import React, { useEffect, useState } from "react";
import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";
import AllProjectStyle from "../all-projects/allproject.module.css";
import { Col, Container, Row } from "react-bootstrap";
import Bunglow from "../../assets/img/project/bunglow.png";
import Office from "../../assets/img/project/office.png";
import { fetchProject } from "../../axios/axios";
import { DOMAIN } from "../../api/url";
import Loader from "../../assets/img/icon/preloader.svg";

export default function AllProject() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    setLoading(true);
    await fetchProject().then((res) => {
      setData(res.data.Project.map(project => ({
        ...project,
        photoPath: typeof project.photoPath === 'string' ? JSON.parse(project.photoPath) : project.photoPath
      })));
      setLoading(false);
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

 
  const [data2, setData2] = useState([])
  return (
    <>
      <section className="bg-white">
        <Container
          style={{
            transform: "translateY(110px)",
            paddingBottom: "150px",
          }}
        >
          <Row className="justify-content-bewteen">
            {!loading ? (
              data !== null ? (
                <>
                  {data?.map((item, index) => {
                    return (
                      <Col className="my-3 position-relative" lg={3} key={"cardcontain" + index} onClick={()=>setData2(item.photoPath)}>
                        <div className={` ${AllProjectStyle.wrapper}`}>
                          <div className={` ${AllProjectStyle.project_card}`}>
                            {/* <a
                              className="text-decoration-none"
                              data-fancybox="gallery"
                              href={DOMAIN + item.photoPath}
                            > */}
                              <img
                                className="img-fluid"
                                alt="gallerImg"
                                src={DOMAIN + item.photoPath[0]}
                              />
                              
                              <div className={` ${AllProjectStyle.info}`}>
                                
                                <h1 className={AllProjectStyle.heading} onClick={()=>setData2(item.photoPath)}>{item.title}</h1>
                                <p className={AllProjectStyle.description}>{item.description}</p>
                                
                              </div>


                            
                          </div>
                        </div>
                        {data2 && ( 
                              <div className="d-flex cursor-pointer justify-content-between position-absolute top-0 end-0 w-100 h-100">
                              {data2.map((item2,index)=>(
                                <a
                                key={index}
                                style={{width:'-webkit-fill-available'}}
                                className="text-decoration-none"
                                data-fancybox="gallery"
                                href={DOMAIN + item2}
                              >
                                {/* <img
                                className="img-fluid"
                                alt="gallerImg"
                                src={DOMAIN + item2}
                                style={{height:'250px !important'}}
                              /> */}
                             <i onClick={()=>setData2(item.photoPath)} className="fa fa-eye w-100 h-100" style={{visibility:'hidden'}}></i>
                              </a>
                              ))}
                              </div>
                             
                            )}
                      </Col>
                    );
                  })}
                </>
              ) : (
                <>
                  <h5>Can't Connect to server</h5>
                </>
              )
            ) : (
              <>
              
              </>
            )}
          </Row>
        </Container>
      </section>
    </>
  );
}
