import origin from "axios";
import {
  BASEURL,
  GETABOUT,
  GETCOUNTERS,
  GETFEATURES,
  GETHOME,
  GETPROJECTS,
  GETSERVICES,
  GETTESTIMONIALS,
  GETVRTOUR,
  GETWEBVRTOUR,
  POSTENQUIRY,
} from "../api/url";
import { store } from "../app/store";
import { startLoader, stopLoader } from "../app/counterReducer";
// import { useSelector } from "react-redux";

const axios = origin.create({
  baseURL: BASEURL,
});

// Axios interceptor for request
axios.interceptors.request.use(
  (config) => {
    // console.log("store ", store.getState())
    // console.log('url', config.url);
    const isForm = store.getState().isform;
    if(config.url !== 'addEnquiry'){
      store.dispatch(startLoader())
    }
    // setLoading(true); // Show loader when request is made
    return config;
  },
  (error) => {
    store.dispatch(startLoader());
    // setLoading(false); // Hide loader on error
    return Promise.reject(error);
  }
);

// Axios interceptor for response
axios.interceptors.response.use(
  (response) => {
    // console.log('url', response.config.url);
    const isForm = store.getState().isform;
    if(response.config.url !== 'addEnquiry'){
      store.dispatch(stopLoader());
    }
    // setLoading(false); // Hide loader when response is received
    return response;
  },
  (error) => {
    store.dispatch(stopLoader());
    // setLoading(false); // Hide loader on error
    return Promise.reject(error);
  }
);
export const fetchHome = async () => {
  const res = await axios.get(GETHOME);
  return res;
};

export const fetchAbout = async () => {
  const res = await axios.get(GETABOUT);
  return res;
};

export const fetchServices = async () => {
  const res = await axios.get(GETSERVICES);
  return res;
};

export const fetchCounter = async () => {
  const res = await axios.get(GETCOUNTERS);
  return res;
};

export const fetchFeatures = async () => {
  const res = await axios.get(GETFEATURES);
  return res;
};

export const fetchProject = async () => {
  const res = await axios.get(GETPROJECTS);
  return res;
};

export const fetchVrTour = async () => {
  const res = await axios.get(GETVRTOUR);
  return res;
};
export const fetchWebVrTour = async () => {
  const res = await axios.get(GETWEBVRTOUR);
  return res;
};
export const fetchTestimonials = async () => {
  const res = await axios.get(GETTESTIMONIALS);
  return res;
};

export const postEngquiry = async (data) => {
  const res = await axios.post(POSTENQUIRY, {
    name: data.name,
    email: data.email,
    phone: data.mobile,
    message: data.message,
  });
  return res;
};

axios.interceptors.request.use(
  async (config) => {
    const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FwaS5la2F0dGFzdHVkaW8uY29tL2FwaS9sb2dpbiIsImlhdCI6MTcxMTUzMjI1NSwiZXhwIjoxMDQ1NTg5ODc1NTUsIm5iZiI6MTcxMTUzMjI1NSwianRpIjoiR21nMmNBVlIyelIxejV1bCIsInN1YiI6IjEiLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.EmlI0-14K59-Ged420ChfU8CkOYHFY7bYH35gImjxMs";
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    return config;
  }
  // async (error) => {
  //     Promise.reject(error);
  //     await store.dispatch(stoploading());
  //     await store.dispatch(
  //         setSnackbar({
  //             open: false,
  //             msg: "something went wrong",
  //             sevarity: "error",
  //         })
  //     );
  // }
);
