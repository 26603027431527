// counterReducer.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  count: 0,
  loader: false,
  isform: false,
};

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    startLoader: (state) => {
      state.loader = true;
    },
    stopLoader: (state) => {
      state.loader = false;
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    increment: (state) => {
      state.count += 1;
    },
    decrement: (state) => {
      state.count -= 1;
    },
    reset: (state) => {
      state.count = 0;
    },
    toggleIsForm: (state, action) =>{
      state.isform = action.payload;
    }
  }
});

export const { increment, decrement, reset, startLoader, stopLoader, toggleIsForm } = counterSlice.actions;

export default counterSlice.reducer;
