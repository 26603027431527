import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../about/about.css";
import About1 from "../../assets/img/resource/alphabet-image.png";
import About2 from "../../assets/img/resource/image-1.jpg";

import { fetchAbout } from "../../axios/axios";
import { useRef } from "react";
const About = () => {
  const aboutRef = useRef(null);
  const [data, setData] = useState([]);
  
  const fetchData = async () => {
    await fetchAbout().then((res) => {
      setData(res.data.about);
    });
  };


 

  useEffect(() => {

    fetchData();

    if (aboutRef.current) {
      // aboutRef.current.scrollIntoView({ behavior: "smooth" });
    }

  }, []);
  return (
    <div ref={aboutRef} >
      <section className="aboutbg about_section" id="about">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="inner_column align-items-start">
                <div className="title_box">
                  <h1 className="d-none d-lg-block">
                    About <br/>
                    Us
                  </h1>
                  <div className="sec-title mb-0 d-block d-lg-none">
            <span className="float-text">About</span>
            <h2>About Us</h2>
          </div>
                </div>
                
                <div className="img_box d-none d-lg-block">
                  <div className="img_1">
                    <img className="img-fluid" src={About1} alt="about" />
                  </div>
                  <div className="img_2">
                    <img className="img-fluid" src={About2} alt="about" />
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              {data.map((item, index) => {
                return (
                  <div key={"aboutkey" + index} className="inner_column2">
                    <div className="content_box">
                      <div className="title">
                        <h2>{item.title}</h2>
                      </div>
                      <p style={{textAlign:'justify'}}>{item.description}</p>
                    </div>
                  </div>
                );
              })}
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default About;
