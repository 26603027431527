export const DOMAIN = "https://api.ekattastudio.com/";
// export const DOMAIN = "http://127.0.0.1:8000/";
export const BASEURL = DOMAIN + "api/";

export const GETHOME = "home";
export const GETABOUT = "about";
export const GETSERVICES = "services";
export const GETCOUNTERS = "counter";
export const GETFEATURES = "features";
export const GETPROJECTS = "project";
export const GETVRTOUR = "VRTours";
export const GETWEBVRTOUR = "webVrTours";
export const GETTESTIMONIALS = "testimonials";
export const POSTENQUIRY = "addEnquiry";
