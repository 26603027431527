import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../assets/img/icon/logo.png";

function Footer() {
  
  const hostURL = "/";

  const orangeColor = {
    color: "#ff8a00",
  };

  return (
    <>
      <section className="footer_section">
        <Container>
          <Row className="footer_row">
            <Col lg={4} className="position-relative">
              <div className="footer_widget">
                <div className="footer_logo">
                  <figure className="mb-2">
                    <a href={hostURL}>
                      <img src={Logo} alt="logo" />
                    </a>
                  </figure>
                </div>
                <div className="widget-content">
                  <div className="text-white mt-4 " >
                    E-katta Studio is a firm of experienced professionals
                    offering comprehensive services in architectural design,
                    interior design for residential, commercial, and
                    institutional clients in Aurangabad & Maharashtra.
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="footer-widget gallery-widget position-relative">
                <h2 className="widget-title text-white mt-4 mt-lg-0">NOTE </h2>
                <div className="widget-content ms-2">
                  <div className="outer clearfix">
                    <p className="text-white mt-3" >
                    This drawing and design is under the copyright of ID. S. J. Untwal and should not be used in part or full without the written consent of the interior designer.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} style={{ zIndex: "99" }}>
              <div className="footer-widget links-widget">
                <h2 className="widget-title">Contact Us</h2>
                <div className="widget-content">
                  <div className="row">
                    <div className="mb-2 col-2 pr-0 text-center">
                      {" "}
                      <i style={orangeColor} className="fa px-2 fa-phone"></i>
                    </div>
                    <div className="col-10 pl-0 ">
                      <Link
                        className="text-white"
                        style={{ textDecoration: "none" }}
                        to=""
                        onClick={() => (window.location = "tel:9970732705")}
                      >
                        +91 9970732705 , 
                      </Link>
                      {"   "}
                      <Link
                        className="text-white"
                        to=""
                        style={{ textDecoration: "none" }}
                        onClick={() => (window.location = "tel:9518944272")}
                      >
                        +91 9518944272
                      </Link>
                    </div>
                    <div className="col-2 pr-0 mb-2 text-center">
                      <i style={orangeColor} className="fa fa-envelope"></i>
                    </div>
                    <div className="col-10 pl-0">
                      <Link
                        className="text-white"
                        to=""
                        style={{ textDecoration: "none" }}
                        onClick={() =>
                          (window.location = "mailto:ekattastudio@gmail.com")
                        }
                      >
                        ekattastudio@gmail.com
                      </Link>
                    </div>
                    <div className="col-2 pr-0 text-center">
                      <i style={orangeColor} className="fa fa-map-marker"></i>
                    </div>
                    <div className="col-10 pl-0">
                      <span className="text-white">
                        C-104, Tulsi Arcade Cannought Place, Aurangabad.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="footer-bottom">
          <div className="auto-container">
            <div className="inner-container clearfix">
              <div className="social-links pt-2">
                <ul className="social-icon-two d-flex list-unstyled justify-content-center gap-2">
                  <li>
                    <Link
                      to={
                        "https://www.facebook.com/shyam.untwal.7/"
                      }
                      target="_blank"
                    >
                      <i className="px-2 text-white fa fa-facebook"></i>
                    </Link>
                  </li>

                  <li>
                    <Link to={"https://pin.it/5sdD4hrLg"} target="_blank">
                      <i className="px-2 text-white fa fa-pinterest"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={
                        "https://www.instagram.com/ekatta.studio?igsh=MThlM2x2YnJpbTlnYQ=="
                      }
                      target="_blank"
                    >
                      <i className="px-2 text-white fa fa-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to={"https://wa.me/919970732705"} target="_blank">
                      <i className="px-2 text-white fa fa-whatsapp"></i>
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="text-center">
                <p className="text-white pt-2 mb-0">
                  ©{" "}
                  <Link to="" className="common_color text-decoration-none">
                    Ekatta Studios{" "}
                  </Link>
                  {(new Date().getFullYear())}. All Rights Reserved Design by{" "}
                  <Link
                    to="https://ekatta.in/"
                    target="_blank"
                    className="common_color text-decoration-none"
                  >
                    Ekatta Innovators LLP
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
