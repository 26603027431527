import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, HashRouter } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import "./index.scss";
import pannellum from "pannellum";
import "pannellum/build/pannellum.css";
import { Provider } from "react-redux"; 
import "./assets/css/flaticon.css";
import { store } from "./app/store";
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Suspense>
    {/* <BrowserRouter basename="/ekattastudio/ekatta_studio_web/build/"> */}
    <HashRouter >
      
      <Provider store={store}>
      <App />
      </Provider>
    </HashRouter>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
